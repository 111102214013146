import {
  type Job,
  type JobTitle,
  type JobWithRelations,
  PayRatePeriod,
  ScreeningAction,
  ScreeningType,
} from '@factoryfixinc/ats-interfaces';

import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { LocalJobData } from './local-job-data.type';
import type {
  GeneratedJobDescriptionDocument,
  GeneratedScreenerQuestionsDocument,
} from '@/core/shared/job/job.type';
import type { LocalScreenerQuestion } from './types/local-screener-question.type';

export const useCopilotActivationStore = defineStore(
  'copilot-activation',
  () => {
    const selectedProjectId = ref<number | null>(null);
    const selectedJobId = ref<number | null>(null);
    const selectedJob = ref<JobWithRelations<
      | 'jobTitle'
      | 'screenerQuestions'
      | 'jobQualifications'
      | 'jobResponsibilities'
      | 'jobTaxonomyKnowledgeDisciplineMaps'
      | 'jobTaxonomyMachineMaps'
    > | null>(null);
    const selectedJobTitle = ref<JobTitle | null>(null);

    // Job Description Generation
    const jobDescriptionDocumentId = ref('');
    const generatedJobDescriptionDocument = ref<GeneratedJobDescriptionDocument | null>(null);
    const jobDescriptionPollingIntervalId = ref<number | null>(null);
    const jobDescriptionPollingIntervalTime = 2000;
    const jobDescriptionPollingMaxAttempts = 150;
    const jobDescriptionPollingTriedAttempts = ref(0);
    const isGeneratingJobDescription = ref(false);
    const stopGeneratingJobDescriptionFlag = ref(false);

    // Screener Questions Generation
    const generatedScreenerQuestionsDocumentId = ref('');
    const generatedScreenerQuestionsDocument = ref<GeneratedScreenerQuestionsDocument | null>(null);
    const screenerQuestionsPollingIntervalId = ref<number | null>(null);
    const screenerQuestionsPollingIntervalTime = 2000;
    const screenerQuestionsPollingMaxAttempts = 150;
    const screenerQuestionsPollingTriedAttempts = ref(0);
    const isGeneratingScreenerQuestions = ref(false);
    const stopGeneratingScreenerQuestionsFlag = ref(false);

    const jobStatus = ref<Job['status'] | null>(null);

    const localJobData = ref<LocalJobData>({
      employerName: '',
      jobTitleId: null,
      displayTitle: null,
      employerAssigneeId: null,
      hireType: null,
      shift: null,
      startTime: null,
      endTime: null,
      description: '',
      rawDescription: '',
      payRateMin: null,
      payRateMax: null,
      payRateSalaryMin: null,
      payRateSalaryMax: null,
      payRatePeriod: PayRatePeriod.HOURLY,
      minimumApplicantsPerWeek: 0,
      hiresPerPosition: 0,
      street1: '',
      city: '',
      state: '',
      postalCode: '',
      anonymous: false,
    });

    // Job Description util
    const localJobDescription = ref<string>('');

    // Skill Keywords
    const localKnowledgeDisciplines = ref<number[]>([]);
    const localMachines = ref<number[]>([]);
    const localBrandsPerMachine = ref<{ [key: number]: number[] }>({});

    // Candidate Screening
    const localScreeningAction = ref<ScreeningAction | null>(null);
    const localScreeningType = ref<ScreeningType | null>(null);
    const localEmployerCareerSiteUrl = ref<string | null>(null);

    // Screener Questions
    const localScreenerQuestions = ref<LocalScreenerQuestion[]>([]);

    // Related data needed for the job flow
    const jobTitles = ref<JobTitle[]>([]);

    // use to run validation and know validations should run on all the fields
    const hasTriedToUpdateJob = ref(false);

    return {
      selectedProjectId,
      selectedJobId,
      selectedJob,
      localJobData,
      localKnowledgeDisciplines,
      localMachines,
      localBrandsPerMachine,
      localScreenerQuestions,
      jobTitles,
      selectedJobTitle,
      jobDescriptionDocumentId,
      generatedJobDescriptionDocument,
      jobDescriptionPollingIntervalId,
      jobDescriptionPollingIntervalTime,
      jobDescriptionPollingMaxAttempts,
      jobDescriptionPollingTriedAttempts,
      isGeneratingJobDescription,
      stopGeneratingJobDescriptionFlag,
      generatedScreenerQuestionsDocumentId,
      generatedScreenerQuestionsDocument,
      screenerQuestionsPollingIntervalId,
      screenerQuestionsPollingIntervalTime,
      screenerQuestionsPollingMaxAttempts,
      screenerQuestionsPollingTriedAttempts,
      stopGeneratingScreenerQuestionsFlag,
      isGeneratingScreenerQuestions,
      localScreeningAction,
      localScreeningType,
      localEmployerCareerSiteUrl,
      localJobDescription,
      jobStatus,
      hasTriedToUpdateJob,
    };
  },
  { persist: false },
);
