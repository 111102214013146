import type { PromisePoolAllSettledResult } from '@/utils/promise.util';
import type { Pagination } from '@factoryfixinc/ats-interfaces/dist/payloads/ats-project/search-projects.type';

export interface ConversationIndexHeaders {
  headers: ConversationIndex[];
}

export interface ApplicationIndex {
  applicationId: number;
  conversationIndexId: number;
  jobId: number;
  employerId: number;
  proId: number;
  applicationStatus: string | null;
  applicationApplyTs: string | null;
  score?: number;
  jobStatus: string;
  createTs: string;
  updateTs: string;
  jobDisplayTitle?: string;
  initiatedByCopilot: boolean;
  initiatedByOutreach: boolean;
}

export interface ApplicationIndexWithJobApplicantSharedFields {
  jobId: number;
  score?: number;
}

export interface ConversationIndex {
  conversationId: number;
  lastMessageText: string;
  lastMessageSentTs: string;
  lastProJobTitle?: string;
  lastProEmployerName?: string;
  proId: number;
  proName: string;
  proEmail: string;
  proPhoneNumber: string;
  readStatus: boolean;
  updateTs: string;
  applicationIndexes?: ApplicationIndex[];
  primaryApplicationIndex: Partial<ApplicationIndex>;
  isBulkSelection?: boolean;
}

export enum SearchConversationOrderByFields {
  LAST_MESSAGE_SENT_TS = 'lastMessageSentTs',
  SCORE = 'score',
  APPLICATION_APPLY_TS = 'applicationApplyTs',
}

export type OrderByDirection = 'asc' | 'desc';

export interface SearchConversationOrderBy {
  field: SearchConversationOrderByFields;
  direction: OrderByDirection;
}

export interface SearchConversationIndex {
  onlyCurrentUser: boolean;
  onlyUnread: boolean;
  jobIds: number[];
  conversationIds: number[];
  applicationStatus: string[];
  textual: string;
  pagination: Pagination;
  orderBy: SearchConversationOrderBy;
}

export type FetchConversationIndexesInput = {
  employerId: number;
  params: Partial<SearchConversationIndex>;
};

export type FetchConversationIndexByApplicantIdInput = {
  employerId: number;
  applicationId: number;
};

export enum ApplicationBulkActions {
  MOVE_TO_ENGAGED,
  MOVE_TO_REVIEWED,
  MOVE_TO_REJECTED,
  MOVE_TO_REJECTED_W_REASON,
  EXPORT_CANDIDATES,
}

export interface BulkChangeApplicationStatusResult {
  result: PromisePoolAllSettledResult<void>[];
  successCount: number;
  errorCount: number;
}
