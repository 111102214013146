/* eslint-disable max-lines */
import { useConversationIndexStore } from '@/core/conversations/conversation-index/conversation-index.store';
import { useMeStore } from '@/core/shared/me/me.store';

import { useConversationProProfileStore } from '../pro-profile/pro-profile.store';
import { ConversationIndexPersistence } from './conversation-index.persistence';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import JobService from '@/core/shared/job/job.service';

import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import {
  type ApplicationIndex,
  type ConversationIndex,
  type SearchConversationIndex,
  SearchConversationOrderByFields,
} from '@/core/conversations/conversation-index/conversation-index.type';
import type { Job, UserProfileWithRelations } from '@factoryfixinc/ats-interfaces';
import { isEqual } from 'radash';
import { unique } from '@/utils/arrays/unique.util';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import ProjectService from '@/core/shared/project/project.service';
import ConversationMessageService from '../message/conversation-message.service';
import type { UserMessageStatus } from '../message/types/get-user-message-status.type';
import { ErrorService } from '@/core/shared/errors/error.service';

export default class ConversationIndexService {
  private store = useConversationIndexStore();
  private persistence = new ConversationIndexPersistence();

  private proProfileService = new ProProfileService();
  private projectService = new ProjectService();
  private jobService = new JobService();
  private conversationMessageService = new ConversationMessageService();

  private meStore = useMeStore();
  private conversationProfileStore = useConversationProProfileStore();

  public get selectedConversationIndex(): ConversationIndex | null {
    return this.store.selectedConversationIndex || null;
  }

  public set selectedConversationIndex(conversationIndex: ConversationIndex | null) {
    this.store.selectedConversationIndex = conversationIndex;
  }

  public get conversationIndexSearch(): SearchConversationIndex {
    return this.store.conversationSearch;
  }

  public set conversationIndexSearch(ref: SearchConversationIndex) {
    this.store.conversationSearch = ref;
  }

  public get conversationIndexes(): ConversationIndex[] {
    return this.store.conversationIndexes || [];
  }

  public set conversationIndexes(conversationIndexes: ConversationIndex[]) {
    this.store.conversationIndexes = conversationIndexes;
  }

  public get loadingConversationIndexes(): boolean {
    return this.store.loadingConversationIndexes;
  }

  public set loadingConversationIndexes(loading: boolean) {
    this.store.loadingConversationIndexes = loading;
  }

  public get selectingConversationIndex(): boolean {
    return this.store.selectingConversationIndex;
  }

  public set selectingConversationIndex(selecting: boolean) {
    this.store.selectingConversationIndex = selecting;
  }

  public get selectedStatusList(): string[] {
    return this.store.selectedStatusList;
  }

  public set selectedStatusList(statuses: string[]) {
    this.store.selectedStatusList = statuses;
  }

  public get searchText(): string {
    return this.store.conversationSearch.textual;
  }

  public set searchText(text: string) {
    this.store.conversationSearch.textual = text;
  }

  public get showSearchField(): boolean {
    return this.store.showSearchField;
  }

  public set showSearchField(show: boolean) {
    this.store.showSearchField = show;
  }

  public get visitedTabRouteName(): string {
    return this.store.visitedTabRouteName;
  }

  public set visitedTabRouteName(name: string) {
    this.store.visitedTabRouteName = name;
  }

  public get selectedStatusFilters(): string[] {
    return this.store.selectedStatusFilters;
  }

  public set selectedStatusFilters(statuses: string[]) {
    this.store.selectedStatusFilters = statuses;
  }

  public get selectedProMessageStatus(): UserMessageStatus | undefined {
    return this.conversationProfileStore.selectedProMessageStatus;
  }

  public get lastPageWithResults(): number | null {
    return this.store.lastPageWithResults;
  }

  private resetConversationIndexSearch(): void {
    this.store.conversationSearch = {
      onlyCurrentUser: false,
      onlyUnread: false,
      jobIds: [],
      conversationIds: [],
      applicationStatus: [
        JobApplicantStatus.CONTACT,
        JobApplicantStatus.NEW,
        JobApplicantStatus.CLIENT,
        JobApplicantStatus.REVIEW,
        JobApplicantStatus.INTERVIEW,
        JobApplicantStatus.OFFER,
        JobApplicantStatus.HIRED,
      ],
      textual: '',
      orderBy: {
        field: SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS,
        direction: 'desc',
      },
      pagination: {
        page: 1,
        itemsPerPage: 25,
      },
    };
  }

  public resetConversationIndexes(): void {
    this.conversationIndexes = [];
  }

  public resetConversationIndexesListAndSearch(): void {
    this.resetConversationIndexSearch();
    this.resetConversationIndexes();
  }

  public getConversationIndexJobIds(conversationIndexes?: ConversationIndex[]): number[] {
    const data = conversationIndexes || this.conversationIndexes;
    return data.map((r) => r?.applicationIndexes?.map((index) => index?.jobId) ?? [])?.flat() ?? [];
  }

  private getPrimaryApplicationJobIds(
    conversationIndexes: ConversationIndex[],
  ): [Set<number>, ConversationIndex[]] {
    const primaryApplicationJobIds = new Set<number>();
    const conversationIndexesWithPrimaryApplication = conversationIndexes.map(
      (conversationIndex: ConversationIndex) => {
        const primaryApplicationIndex = ProProfileService.findPrimaryApplication<ApplicationIndex>(
          conversationIndex.applicationIndexes,
        );
        if (primaryApplicationIndex?.jobId) {
          primaryApplicationJobIds.add(primaryApplicationIndex.jobId);
        }
        return { ...conversationIndex, primaryApplicationIndex: primaryApplicationIndex || {} };
      },
    );
    return [primaryApplicationJobIds, conversationIndexesWithPrimaryApplication];
  }

  public modifyConversationIndexPrimaryApplicationIndex(
    conversationIndex?: ConversationIndex,
    applicationIndex?: Partial<ApplicationIndex>,
  ): void {
    if (!conversationIndex || !applicationIndex) {
      return;
    }

    const updatedConversationIndexes = this.conversationIndexes.map((index) => {
      if (index.conversationId !== conversationIndex.conversationId) {
        return index;
      }

      if (index.primaryApplicationIndex) {
        return {
          ...index,
          primaryApplicationIndex: { ...index.primaryApplicationIndex, ...applicationIndex },
        };
      }

      return index;
    });

    this.conversationIndexes = updatedConversationIndexes;
  }

  private getJobDisplayTitles(
    jobIds: Map<number, Job & { jobTitle: { title: string } }>,
    conversationIndexes: ConversationIndex[],
  ): ConversationIndex[] {
    const updatedConversationIndexes = conversationIndexes.map((conversationIndex) => {
      const jobId = conversationIndex.primaryApplicationIndex?.jobId;
      const primaryApplicationIndex = conversationIndex.primaryApplicationIndex;

      if (jobId) {
        const job = jobIds.get(jobId);
        const jobDisplayTitle = job?.jobTitle?.title || job?.displayTitle || '';
        return {
          ...conversationIndex,
          primaryApplicationIndex: { ...primaryApplicationIndex, jobDisplayTitle },
        };
      }
      return { ...conversationIndex, primaryApplicationIndex: {} };
    });
    return updatedConversationIndexes;
  }

  private async addPrimaryApplicationOnConversationIndexes(
    conversationIndexes: ConversationIndex[],
  ): Promise<ConversationIndex[]> {
    // 1. Extracts the jobIds from the primary application indexes
    const [primaryApplicationJobIds, conversationIndexesWithPrimaryApplication] =
      this.getPrimaryApplicationJobIds(conversationIndexes);

    // 2. Fetches the jobs for the primary application indexes
    const jobs = await this.jobService.fetchJobs(
      [...primaryApplicationJobIds],
      this.meStore.employer?.id as number,
    );

    // 3. Creates a map to easily access the job by its id
    const idToJobMap = new Map();
    jobs.forEach((job) => idToJobMap.set(job.id, job));

    // 4. Updates the conversation indexes with the job display title
    const updatedConversationIndexes = this.getJobDisplayTitles(
      idToJobMap,
      conversationIndexesWithPrimaryApplication,
    );

    return updatedConversationIndexes;
  }

  public async updateConversationIndexSearch(
    search: Partial<SearchConversationIndex>,
    opts?: {
      skipOnSameSearch?: boolean;
      resetConversationIndexes?: boolean;
      skipTracking?: boolean;
    },
  ): Promise<void> {
    const existingSearch = this.conversationIndexSearch;
    const newSearchWithJobIds = { ...this.conversationIndexSearch, ...search };

    if (opts?.skipOnSameSearch && isEqual(existingSearch, newSearchWithJobIds)) {
      return;
    }

    if (opts?.resetConversationIndexes) {
      this.resetConversationIndexes();
    }

    this.resetConversationIndexSearch();

    newSearchWithJobIds.applicationStatus =
      this.getDefaultApplicationStatusesIfEmpty(newSearchWithJobIds);

    this.conversationIndexSearch = newSearchWithJobIds;
    try {
      await this.fetchConversationIndexes();
    } catch (error) {
      ErrorService.captureException(error);
    } finally {
      if (!opts?.skipTracking) {
        TrackingService.trackAction(
          TrackingActionName.CONVERSATIONS_FILTERED_AND_SORTED_OR_SEARCHED,
          {
            filters: {
              textual_search: newSearchWithJobIds.textual,
              status: newSearchWithJobIds.applicationStatus,
              unread_only: newSearchWithJobIds.onlyUnread,
              only_current_user: newSearchWithJobIds.onlyCurrentUser,
              order_by: newSearchWithJobIds.orderBy,
            },
          },
        );
      }
    }
  }

  public async selectConversationIndex(conversationIndex: ConversationIndex): Promise<void> {
    try {
      this.selectingConversationIndex = true;
      if (!conversationIndex?.proId) return;
      this.selectedConversationIndex = conversationIndex;
      // getProProfile already fetches Notes
      await this.proProfileService.getProProfile(
        this.meStore.employer?.id as number,
        conversationIndex.proId,
      );
      Promise.allSettled([
        await this.projectService.fetchProjectsTitleByJobIds(
          this.getConversationIndexJobIds([conversationIndex]),
        ),
        await this.fetchAndSetSelectedProMessageStatus(conversationIndex.proId),
      ]);
    } catch (error) {
      throw error;
    } finally {
      this.selectingConversationIndex = false;
    }
  }

  public clearSelectedProAndApplication(): void {
    this.conversationProfileStore.clearSelectedProApplication();
    this.conversationProfileStore.clearSelectedPro();
  }

  private deduplicateConversationIndexes(
    conversationIndexes: ConversationIndex[],
  ): ConversationIndex[] {
    return unique(conversationIndexes, (index) => index.conversationId);
  }

  private async aggregateDataOnConversationIndexes(
    conversationIndexes: ConversationIndex[],
  ): Promise<ConversationIndex[]> {
    const aggregatedConversationIndexes =
      await this.addPrimaryApplicationOnConversationIndexes(conversationIndexes);
    await this.projectService.fetchProjectsTitleByJobIds(
      this.getConversationIndexJobIds(aggregatedConversationIndexes),
    );
    return aggregatedConversationIndexes;
  }

  private mergeAndDeduplicateConversationIndexes(
    conversationIndexes: ConversationIndex[],
  ): ConversationIndex[] {
    let deduplicatedConversationIndexes: ConversationIndex[] = [];
    if (this.conversationIndexSearch.pagination.page > 1) {
      deduplicatedConversationIndexes = this.deduplicateConversationIndexes([
        ...this.conversationIndexes,
        ...conversationIndexes,
      ]);
    } else {
      deduplicatedConversationIndexes = this.deduplicateConversationIndexes(conversationIndexes);
    }
    return deduplicatedConversationIndexes;
  }

  private async fetchInternalConversationIndexes(): Promise<ConversationIndex[]> {
    const indexes = await this.persistence.fetchConversationIndexes({
      employerId: this.meStore.employer?.id as number,
      params: this.conversationIndexSearch,
    });
    if (indexes.length > 0) {
      this.store.lastPageWithResults = this.conversationIndexSearch.pagination.page;
    }
    return indexes;
  }

  private async commonConversationIndexFetchActions(
    conversationIndexes: ConversationIndex[],
  ): Promise<ConversationIndex[]> {
    const aggregatedConversationIndexes =
      await this.aggregateDataOnConversationIndexes(conversationIndexes);
    return this.mergeAndDeduplicateConversationIndexes(aggregatedConversationIndexes);
  }

  public async fetchConversationIndexes(): Promise<void> {
    this.loadingConversationIndexes = true;
    try {
      const conversationIndexes = await this.fetchInternalConversationIndexes();
      this.conversationIndexes =
        await this.commonConversationIndexFetchActions(conversationIndexes);
      if (this.conversationIndexSearch.pagination.page === 1) {
        await this.selectConversationIndex(this.conversationIndexes[0]);
      }
    } catch (error) {
      throw error;
    } finally {
      this.loadingConversationIndexes = false;
    }
  }

  public async fetchConversationIndexesByApplicantId(applicationId: number): Promise<void> {
    this.loadingConversationIndexes = true;
    try {
      const conversationIndex = await this.persistence.fetchConversationIndexByApplicantId({
        employerId: this.meStore.employer?.id as number,
        applicationId,
      });

      // Remove all the other applications from the conversationIndex so we dont get other application as primary
      conversationIndex.applicationIndexes = conversationIndex.applicationIndexes?.filter(
        (index) => index.applicationId === applicationId,
      );

      // Set the jobIds to the same as the job of the application
      // So we don't fetch all the conversation indexes that are not related to the job
      const application = conversationIndex.applicationIndexes?.[0];
      if (application) {
        this.conversationIndexSearch.jobIds = [application.jobId];
      }

      const conversationIndexes = await this.fetchInternalConversationIndexes();
      // Prepend the conversationIndex to the conversationIndexes array
      this.conversationIndexes = await this.commonConversationIndexFetchActions([
        conversationIndex,
        ...conversationIndexes,
      ]);

      await this.selectConversationIndex(this.conversationIndexes[0]);
    } catch (error) {
      throw error;
    } finally {
      this.loadingConversationIndexes = false;
    }
  }

  public async fetchConversationIndexesByConversationId(
    conversationId: number,
    jobId?: number,
  ): Promise<void> {
    this.loadingConversationIndexes = true;
    try {
      const conversationIndex = await this.persistence.fetchConversationIndexes({
        employerId: this.meStore.employer?.id as number,
        params: {
          ...this.conversationIndexSearch,
          applicationStatus: this.getAllApplicationStatuses(),
          onlyCurrentUser: false,
          jobIds: jobId ? [jobId] : undefined,
          conversationIds: [conversationId],
        },
      });

      // We want all the other conversations that are related to the same job
      // of the primary application of the conversation we are looking for
      let jobIdToSetAsDefault = jobId;
      if (!jobIdToSetAsDefault && conversationIndex[0]) {
        const primaryApplication = await ProProfileService.findPrimaryApplication<ApplicationIndex>(
          conversationIndex[0].applicationIndexes,
        );
        jobIdToSetAsDefault = primaryApplication?.jobId;
      }
      this.conversationIndexSearch.jobIds = jobIdToSetAsDefault
        ? [jobIdToSetAsDefault]
        : this.conversationIndexSearch.jobIds;

      const conversationIndexes = await this.fetchInternalConversationIndexes();

      // In case the query returns more than one conversationIndex, we need to filter the one we want
      const selectedConversationIndex = conversationIndex.filter(
        (index) => index.conversationId === conversationId,
      );
      // Prepend the conversationIndex to the conversationIndexes array
      this.conversationIndexes = await this.commonConversationIndexFetchActions([
        ...selectedConversationIndex,
        ...conversationIndexes,
      ]);

      await this.selectConversationIndex(this.conversationIndexes[0]);
    } catch (error) {
      throw error;
    } finally {
      this.loadingConversationIndexes = false;
    }
  }

  public filterLocalConversationIndexesByStatus(
    applicationId: number,
    newStatus: JobApplicantStatus,
  ): void {
    // If the selectedStatusList is empty or the newStatus is already included, skip
    if (this.selectedStatusList.length === 0 || this.selectedStatusList.includes(newStatus)) {
      return;
    }

    // Exclude the applicationId from the conversationIndexes
    const conversationIndexes = this.conversationIndexes || [];
    const updatedConversationIndexes = conversationIndexes.filter(
      (conversationIndex) =>
        conversationIndex.applicationIndexes?.some(
          (applicationIndex) =>
            applicationIndex.applicationId !== applicationId &&
            applicationIndex.applicationStatus !== newStatus,
        ),
    );

    this.conversationIndexes = updatedConversationIndexes;
  }

  // Will select or unselect all the conversation indexes
  public toggleBulkActionAllConversationIndexes(isSelectAll: boolean = false): void {
    const updatedConversationIndexes = this.conversationIndexes.map((conversationIndex) => {
      return {
        ...conversationIndex,
        isBulkSelection: isSelectAll,
      };
    });
    this.conversationIndexes = updatedConversationIndexes;
  }

  public async getBulkConversationIndexes(): Promise<
    { proId: number; proProfileId: number; applicationId: number }[] | undefined
  > {
    // Lets check if there are any selected conversation
    const selectedConversationIndexes = this.conversationIndexes.filter(
      (conversationIndex) => conversationIndex.isBulkSelection,
    );
    if (selectedConversationIndexes.length === 0) {
      return;
    }

    const pros: {
      proId: number;
      proProfileId: number | undefined;
      applicationId: number | undefined;
    }[] = [];

    // Lets get the applicationIds from the selected conversation
    for (const conversationIndex of selectedConversationIndexes) {
      const pro = await this.fetchPro(conversationIndex.proId);

      pros.push({
        proId: conversationIndex.proId,
        proProfileId: pro?.pro?.id,
        applicationId: conversationIndex.primaryApplicationIndex?.applicationId,
      });
    }
    if (pros.some((pro) => !pro.applicationId || !pro.proProfileId)) {
      return;
    } else {
      return pros as { proId: number; proProfileId: number; applicationId: number }[];
    }
  }

  private async fetchPro(proId: number): Promise<UserProfileWithRelations<'pro'> | undefined> {
    try {
      return this.proProfileService.getProProfileById(proId, this.meStore.employer?.id as number);
    } catch (error) {
      return undefined;
    }
  }

  private async fetchAndSetSelectedProMessageStatus(proId: number): Promise<void> {
    this.conversationProfileStore.setSelectedProMessageStatus(
      await this.conversationMessageService.getUserMessageStatus(
        proId,
        this.meStore.employer?.id as number,
      ),
    );
  }

  public async fetchConversationIndexesByProData(proData: string): Promise<{
    count: number;
    headers: ConversationIndex[];
  }> {
    const employerId = this.meStore.employer?.id as number;
    return this.persistence.fetchConversationIndexesByProData({
      employerId,
      proData,
    });
  }

  private getDefaultApplicationStatusesIfEmpty(search: Partial<SearchConversationIndex>): string[] {
    if (search.applicationStatus && search.applicationStatus.length !== 0) {
      return search.applicationStatus;
    }
    return [
      JobApplicantStatus.CONTACT,
      JobApplicantStatus.NEW,
      JobApplicantStatus.CLIENT,
      JobApplicantStatus.REVIEW,
      JobApplicantStatus.INTERVIEW,
      JobApplicantStatus.OFFER,
      JobApplicantStatus.HIRED,
    ];
  }

  private getAllApplicationStatuses(): string[] {
    return [
      JobApplicantStatus.CONTACT,
      JobApplicantStatus.NEW,
      JobApplicantStatus.CLIENT,
      JobApplicantStatus.REVIEW,
      JobApplicantStatus.INTERVIEW,
      JobApplicantStatus.OFFER,
      JobApplicantStatus.HIRED,
      JobApplicantStatus.REJECTED,
    ];
  }
}
