<!-- eslint-disable max-lines -->
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import SearchSvg from '@/assets/svg/search-20.svg?component';

import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import CandidateList from '../CandidateList/CandidateList.vue';
import Divider from '@/components/Shared/Divider/Divider.vue';

import { ErrorService } from '@/core/shared/errors/error.service';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import type { ConversationIndex } from '@/core/conversations/conversation-index/conversation-index.type';

const conversationIndexService = new ConversationIndexService();

const conversationIndexes = ref<ConversationIndex[]>([]);
const candidateCount = ref(0);
const searchText = ref('');
const isSearching = ref(false);
const hasSearchedCandidates = ref(false);

const isSearchButtonDisabled = computed(() => {
  return isSearching.value || searchText.value === '';
});

const candidateCountText = computed(() => {
  let text = '';

  const isMoreThanOne = candidateCount.value > 1;
  const candidateText = isMoreThanOne ? 'candidates' : 'candidate';

  if (!hasSearchedCandidates.value) {
    text = `${candidateText} across all jobs`;
  } else {
    text = `matching ${candidateText} found`;
  }

  if (candidateCount.value > 100) {
    text += ` (showing the top 100)`;
  }

  return text;
});

const refineCandidateSearchInputText = computed(() => {
  let text = '';

  const hasSearchedWithNoResults = hasSearchedCandidates.value && candidateCount.value === 0;
  const hasHundredPlusResults = candidateCount.value > 100;

  if (hasSearchedWithNoResults || hasHundredPlusResults) {
    text = `Didn't find what you were looking for? You might want to refine your search.`;
  }

  return text;
});

// function clearText() {
//   searchText.value = '';
// }

onMounted(() => {
  searchCandidates(true);
});

async function searchCandidates(ignoreValidation = false) {
  if (!ignoreValidation && isSearchButtonDisabled.value) {
    return;
  }

  if (!ignoreValidation) {
    hasSearchedCandidates.value = true;
  }

  try {
    isSearching.value = true;

    const data = await conversationIndexService.fetchConversationIndexesByProData(searchText.value);
    conversationIndexes.value = data.headers ?? [];
    candidateCount.value = data.count ?? 0;
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isSearching.value = false;
  }
}
</script>

<template>
  <div class="relative overflow-y-scroll">
    <!-- Search Input -->
    <div class="sticky left-0 top-0 mb-2 w-full">
      <v-text-field
        autofocus
        placeholder="Search candidates across all jobs"
        v-model="searchText"
        class="pro-data-field__input"
        density="compact"
        variant="solo"
        clearable
        persistent-clear
        @keydown.enter="searchCandidates(false)"
      >
        <template v-slot:prepend-inner>
          <SearchSvg class="text-shade-900" />
        </template>
        <template v-slot:append-inner>
          <button
            class="cursor-pointer rounded-md px-3 py-1.5 text-sm font-semibold"
            :class="{
              'bg-tint-80 text-tint-400': isSearchButtonDisabled,
              'bg-shade-900 text-tint-0': !isSearchButtonDisabled,
            }"
            :disabled="isSearchButtonDisabled"
            @click="searchCandidates(false)"
          >
            <template v-if="isSearching">
              <SpinnerLoader class="mx-4 h-[18px] w-[18px]" dark></SpinnerLoader>
            </template>
            <template v-else> Search </template>
          </button>
        </template>
      </v-text-field>
    </div>

    <p class="my-6 font-sans text-sm text-shade-900">
      <span class="font-bold">{{ candidateCount }}</span>
      <span class="ml-1 font-normal">{{ candidateCountText }}</span>
    </p>

    <div>
      <Divider />
      <!-- Headers -->
      <div class="grid grid-cols-8 gap-8 px-4 py-2">
        <!-- Pro Info -->
        <div class="col-span-4 font-sans text-xs font-bold leading-[18px] text-shade-800">
          <p>Name, info and location</p>
        </div>

        <!-- Recent Work Experience -->
        <div class="col-span-2 font-sans text-xs font-bold leading-[18px] text-shade-800">
          <p>Recent work experience</p>
        </div>

        <!-- Last Active -->
        <div
          class="col-span-2 text-right font-sans text-xs font-bold leading-[18px] text-shade-800"
        >
          <p>Last active</p>
        </div>
      </div>

      <Divider />

      <!-- List -->
      <CandidateList :conversationIndexes="conversationIndexes" />

      <!-- Refine Search -->
      <p class="my-12 text-center text-sm font-normal text-shade-820">
        {{ refineCandidateSearchInputText }}
      </p>
    </div>
  </div>
</template>

<style lang="postcss">
.pro-data-field__input {
  & .v-field {
    @apply box-border border border-solid border-shade-700 pr-1 shadow-none !important;
  }
  & .v-field--variant-solo {
    @apply shadow-none !important;
  }
  & input.v-field__input {
    @apply h-10 min-h-0 bg-white p-0 pl-2 text-sm text-shade-900 placeholder:text-tint-300 placeholder:opacity-100 !important;
  }
  & .v-input__details {
    @apply hidden !important;
  }
}
</style>
